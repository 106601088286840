import React from 'react';
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";
import { graphql } from 'gatsby';
import { RichText } from "prismic-reactjs";
import Layout from "components/Layout";
import LiveIcon from "components/_ui/icons/Live";
import GithubIcon from "components/_ui/icons/Github";
import ContactCTA from "components/_ui/ContactCTA";

const ProjectHeroContainer = styled("div")`
    background: ${colors.grey200};
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    position: relative;
    padding-top: 2.25em;
    margin-bottom: 3.5em;

    img {
        max-width: 600px;

        @media(max-width: ${dimensions.maxwidthMobile}px) {
            width: 95%;
        }
    }
`

const ProjectTitle = styled("div") `
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
`

const ProjectBody = styled("div")`
    max-width: 550px;
    margin: 0 auto;

    .block-img {
        margin-top: 3.5em;
        margin-bottom: 0.5em;

        img {
            width: 100%;
        }
    }

    a {
        color: ${colors.green500};
        text-decoration: none;
    }
`

const ProjectLinks = styled("div")`
    display: flex;
    justify-content: space-around;
    margin-top: 3em;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-weight: bold;
        background: ${colors.orange500};
        border-radius: 4px;
        width: 75px;
        height: 50px;

        &:hover {
            p {
                display: block;
                font-size: 1em;
                color: ${colors.white100};
                text-align: center;
                line-height: 1.25;
            }

            svg {
                display: none;
            }
        }
    }

    p {
        display: none;
    }

    svg {
        width: 35px;
        fill: ${colors.white100};
    }
`


const Project = ({ project, meta }) => {
    return (
        <>
            <Helmet
                title={`${project.project_title[0].text} | House of Crane`}
                titleTemplate={`%s | ${meta.title}`}
                meta={[
                    {
                        name: `description`,
                        content: meta.description,
                    },
                    {
                        property: `og:title`,
                        content: `${project.project_title[0].text} | House of Crane`,
                    },
                    {
                        property: `og:description`,
                        content: meta.description,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:creator`,
                        content: meta.author,
                    },
                    {
                        name: `twitter:title`,
                        content: meta.title,
                    },
                    {
                        name: `twitter:description`,
                        content: meta.description,
                    },
                ].concat(meta)}
            />
            <Layout>
                <ContactCTA />
                <ProjectTitle>
                    {RichText.render(project.project_title)}
                </ProjectTitle>
                {project.project_hero_image && (
                    <ProjectHeroContainer>
                        <img src={project.project_hero_image.url} alt="bees" />
                    </ProjectHeroContainer>
                )}
                <ProjectBody>
                    {RichText.render(project.project_description)}
                    <ProjectLinks>
                        <a href={project.project_repo_link.url}
                            target="_blank" rel="noopener noreferrer">
                            <GithubIcon />
                            <p>View Repo</p>
                        </a>
                        <a href={project.project_demo_link.url}
                            target="_blank" rel="noopener noreferrer">
                            <LiveIcon />
                            <p>Live Demo</p>
                        </a>
                    </ProjectLinks>
                </ProjectBody>
            </Layout>
        </>
    )
}

export default ({ data }) => {
    const projectContent = data.prismic.allProjects.edges[0].node;
    const meta = data.site.siteMetadata;
    return (
        <Project project={projectContent} meta={meta}/>
    )
}

Project.propTypes = {
    project: PropTypes.object.isRequired,
};

export const query = graphql`
    query ProjectQuery($uid: String) {
        prismic {
            allProjects(uid: $uid) {
                edges {
                    node {
                        project_title
                        project_preview_description
                        project_preview_thumbnail
                        project_category
                        project_post_date
                        project_hero_image
                        project_description
                        project_repo_link {
                            ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                            }
                        }
                        project_demo_link {
                            ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                            }
                        }
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
            }
        }
    }
`