import React from "react";

const LiveIcon = () => (
    <svg fillRule="evenodd" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M467 240H45c-24.951 0-45 20.375-45 45v182c0 24.814 20.186 45 45 45h422c24.814 0 45-20.186 45-45V285c0-24.625-20.049-45-45-45zM135 452H75c-8.291 0-15-6.709-15-15V317c0-19.773 30-19.854 30 0v105h45c19.773 0 19.854 30 0 30zm77-15c0 19.773-30 19.854-30 0V317c0-19.773 30-19.854 30 0v120zm119.546-116.367l-30 120c-3.77 15.078-25.322 15.078-29.092 0l-30-120c-4.806-19.084 24.276-26.598 29.092-7.266L287 375.154l15.454-61.787c4.826-19.372 33.888-11.78 29.092 7.266zM437 362c19.773 0 19.854 30 0 30h-45v30h45c19.773 0 19.854 30 0 30h-60c-8.291 0-15-6.709-15-15V317c0-8.291 6.709-15 15-15h60c19.773 0 19.854 30 0 30h-45v30h45zM107.549 86.338L231.211 210h49.578l60.745-60.745C261.557 75.432 161.547 49.206 107.549 86.338zM86.418 107.629C68.978 133.143 65.266 169.195 75.461 210h113.328L86.418 107.629z"
        />
        <path
            d="M255 0C191.926 0 132.528 22.994 85.814 64.603l.313.313c65.114-49.125 184.183-22.482 276.594 63.151l63.464-63.464C379.472 22.994 318.074 0 255 0zM447.023 86.188l-63.208 63.208c17.662 19.048 32.985 39.51 45.965 60.604H467c15.928 0 30.648 5.072 42.808 13.574-6.251-50.926-28.269-98.502-62.785-137.386zM65.123 86.334l-.146-.146c-34.516 38.884-56.534 86.46-62.785 137.386 12.07-8.439 26.669-13.471 42.462-13.546-10.479-48.712-3.406-92.273 20.469-123.694zM362.608 170.603L323.211 210h70.908c-9.447-13.572-19.91-26.812-31.511-39.397z"
        />
    </svg>
);

export default LiveIcon;